import { motion } from 'framer-motion';
import Layout from '../../components/Layout';

function RAGEvaluation() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <Layout>
      <motion.div
        className="paper-container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="paper-content" variants={itemVariants}>
          <h1>Best Practices in RAG Evaluation: A Comprehensive Guide</h1>
          
          <motion.div className="paper-author" variants={itemVariants}>
            <p className="author-info">
              By John Bellew
              <span className="author-title">Chief AI Architect, INFINIA AI</span>
            </p>
          </motion.div>

          <motion.div className="paper-abstract" variants={itemVariants}>
            <h2>Abstract</h2>
            <p>
              Retrieval-Augmented Generation (RAG) has emerged as a powerful approach for enhancing large language models (LLMs) with external knowledge sources. This paper provides a structured evaluation framework for RAG systems, highlighting the challenges in retrieval, augmentation, and generation while offering best practices to optimize performance. We explore evaluation frameworks such as Ragas, Quotient AI, and Arize Phoenix, focusing on search precision, recall, contextual relevance, and response accuracy.
            </p>
          </motion.div>

          <motion.section variants={itemVariants}>
            <h2>1. Introduction</h2>
            <p>
              Building a RAG system is only the first step; ensuring its accuracy and reliability is crucial for real-world applications. This guide aims to assist practitioners in systematically assessing and fine-tuning their RAG models for long-term stability and user satisfaction.
            </p>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2>2. Evaluating RAG Systems</h2>
            <h3>2.1 Why Evaluate?</h3>
            <p>Evaluating a RAG system helps identify shortcomings in:</p>
            <ul>
              <li>Retrieval: Ensuring relevant information is fetched</li>
              <li>Augmentation: Providing complete and accurate contextual information</li>
              <li>Generation: Producing factually correct and coherent responses</li>
            </ul>
          </motion.section>

          <motion.section variants={itemVariants}>
            <h2>3. Recommended Evaluation Frameworks</h2>
            <h3>3.1 Ragas</h3>
            <p>Ragas evaluates retrieval and response quality using metrics such as:</p>
            <ul>
              <li>Faithfulness</li>
              <li>Answer relevancy</li>
              <li>Context recall</li>
              <li>Context precision</li>
              <li>Semantic similarity</li>
            </ul>
          </motion.section>

          {/* Add remaining sections... */}

          <motion.section className="references" variants={itemVariants}>
            <h2>References</h2>
            <ul className="reference-list">
              <li className="reference-item">
                <span className="reference-author">Qdrant.</span> (2023). Best Practices in RAG Evaluation: A Comprehensive Guide.
              </li>
              <li className="reference-item">
                <a 
                  href="https://docs.ragas.io/en/latest/getstarted/testset_generation.html" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="reference-link"
                >
                  <div className="reference-content">
                    <span className="reference-title">Ragas Documentation</span>
                    <p className="reference-description">Official guide for RAG evaluation and test set generation</p>
                  </div>
                  <span className="link-arrow">→</span>
                </a>
              </li>
              <li className="reference-item">
                <a 
                  href="https://docs.arize.com/phoenix/evaluation/concepts-evals/evaluation" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="reference-link"
                >
                  <div className="reference-content">
                    <span className="reference-title">Arize Phoenix Documentation</span>
                    <p className="reference-description">Comprehensive evaluation concepts for LLM systems</p>
                  </div>
                  <span className="link-arrow">→</span>
                </a>
              </li>
              <li className="reference-item">
                <a 
                  href="https://huggingface.co/spaces/mteb/leaderboard" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="reference-link"
                >
                  <div className="reference-content">
                    <span className="reference-title">Hugging Face MTEB Leaderboard</span>
                    <p className="reference-description">Massive Text Embedding Benchmark results</p>
                  </div>
                  <span className="link-arrow">→</span>
                </a>
              </li>
            </ul>
          </motion.section>

          <motion.div className="paper-footer" variants={itemVariants}>
            <p className="copyright">© 2024 INFINIA AI. All rights reserved.</p>
          </motion.div>
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default RAGEvaluation; 