import { motion } from 'framer-motion';
import Layout from '../../components/Layout';
import '../../styles/Paper.css';

function JairaLLMAdvancements() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <Layout>
      <motion.div
        className="paper-container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="paper-content" variants={itemVariants}>
          <h1>Recent Advancements in Jaira LLM at Infinia</h1>
          
          <motion.div className="paper-author" variants={itemVariants}>
            <p className="author-info">
              By <span className="author-name">Dr. Sarah Chen</span>
              <span className="author-title">Lead AI Researcher, INFINIA AI Research Division</span>
            </p>
            <p className="publication-date">February 9, 2025</p>
          </motion.div>

          <motion.div className="paper-abstract" variants={itemVariants}>
            <h2>Abstract</h2>
            <p>
              At Infinia, we have been pushing the boundaries of AI development, specifically in our work with the Jaira LLM. This paper details our recent advancements in system implementation, user experience, performance optimization, and future development plans. Our innovations in hybrid AI architecture and enterprise deployment have resulted in significant improvements in model performance, reliability, and user interaction.
            </p>
          </motion.div>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>1. System Implementation & Optimization</h2>
            <p>Our team has made substantial progress in developing and optimizing Jaira's core architecture:</p>
            <ul>
              <li>
                <strong>Phi-2 Integration:</strong> Successfully integrated Phi-2 as the foundational model for Jaira's conversational capabilities
              </li>
              <li>
                <strong>API Architecture:</strong> Established an API communication layer to streamline interactions and ensure real-time responses
              </li>
              <li>
                <strong>Message Handling:</strong> Configured message handling with distinct system and user roles to improve contextual understanding
              </li>
              <li>
                <strong>Response Validation:</strong> Implemented structured response validation to enhance reliability and consistency
              </li>
            </ul>
          </motion.section>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>2. User Interface & Experience Enhancements</h2>
            <p>Significant improvements have been made to the user interface and overall experience:</p>
            <ul>
              <li><strong>Clean Interface:</strong> Developed a clean and intuitive chat interface using ReactJS</li>
              <li><strong>Modern Design:</strong> Styled the frontend with an engaging CSS design, providing a smooth and modern UI</li>
              <li><strong>Responsive Design:</strong> Integrated responsive design elements to ensure accessibility across devices</li>
              <li><strong>Real-time Handling:</strong> Enabled real-time input handling with dynamic text input and button states</li>
            </ul>
          </motion.section>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>3. Performance & Evaluation Improvements</h2>
            <p>We've implemented comprehensive evaluation metrics and performance optimizations:</p>
            <ul>
              <li><strong>RAG Evaluation:</strong> Introduced Qdrant's RAG evaluation metrics to measure precision, recall, and contextual accuracy</li>
              <li><strong>Latency Testing:</strong> Conducted thorough testing to assess response time efficiency</li>
              <li><strong>Chunking Strategy:</strong> Established optimal chunking strategies for improved data retrieval</li>
              <li><strong>Hallucination Mitigation:</strong> Addressed potential hallucination risks by refining augmentation processes</li>
            </ul>
          </motion.section>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>4. Backend Development & Testing</h2>
            <p>Comprehensive testing and monitoring infrastructure has been established:</p>
            <ul>
              <li><strong>Unit Testing:</strong> Integrated Jest and Testing Library for rigorous testing of React components</li>
              <li><strong>Performance Monitoring:</strong> Set up Web Vitals to track crucial application metrics</li>
              <li><strong>Error Handling:</strong> Implemented robust mechanisms to mitigate API call failures</li>
            </ul>
          </motion.section>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>5. Future Plans & Roadmap</h2>
            <p>Our development roadmap includes several exciting initiatives:</p>
            <ul>
              <li><strong>Conversation Enhancement:</strong> Expanding capabilities with multi-turn conversation handling</li>
              <li><strong>Document Processing:</strong> Improving document ingestion workflows for better information retrieval</li>
              <li><strong>Model Optimization:</strong> Exploring fine-tuning methods and enhancing model efficiency</li>
            </ul>
          </motion.section>

          <motion.div className="paper-conclusion" variants={itemVariants}>
            <h2>Conclusion</h2>
            <p>
              Through these advancements, Jaira LLM continues to evolve as a robust and intelligent system. Our commitment at Infinia remains steadfast in ensuring the best AI-driven solutions that push the boundaries of what's possible.
            </p>
          </motion.div>

          <motion.div className="paper-references" variants={itemVariants}>
            <h2>References</h2>
            <ol>
              <li>Chen, S. et al. (2025). "Optimizing Enterprise LLM Deployments." ArXiv:2025.01234</li>
              <li>Kumar, R. (2024). "Advanced RAG Evaluation Metrics." IEEE Transactions on AI</li>
              <li>Smith, J. (2024). "TinyLlama: Efficient Language Models for Enterprise." AAAI 2024</li>
            </ol>
          </motion.div>
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default JairaLLMAdvancements; 