import React from 'react';
import { motion } from 'framer-motion';

const Services = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const cardVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  return (
    <div className="services-container">
      <motion.h1
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Coming Soon
      </motion.h1>
      <motion.div 
        className="services-grid"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="service-card featured-card" variants={cardVariants}>
          <h2>Watch This Space!</h2>
          <p>We're working on something exciting. Our innovative AI services will be launching soon, bringing cutting-edge artificial intelligence solutions to transform your business.</p>
          <div className="coming-soon-features">
            <h3>What to Expect:</h3>
            <ul className="feature-list">
              <li>✨ Private AI Assistants</li>
              <li>🔍 Custom AI Development</li>
              <li>🔒 Enterprise Solutions</li>
              <li>🚀 Cloud Integration</li>
              <li>⚡ Performance Optimization</li>
            </ul>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Services; 