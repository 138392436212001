import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import newsData from '../data/ai-news.json';
import '../styles/Blog.css';

function Blog() {
  const [activeTag, setActiveTag] = useState('all');
  const [activeType, setActiveType] = useState('all');
  const news = newsData.news;

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.1 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const tags = ['all', ...new Set(news.map(item => item.tag))];
  const types = ['all', 'paper', 'news'];

  const filteredContent = news.filter(item => {
    if (activeType !== 'all' && item.type !== activeType) return false;
    if (activeTag !== 'all' && item.tag !== activeTag) return false;
    return true;
  });

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleTypeChange = (type) => {
    setActiveType(type);
    setActiveTag('all');
  };

  return (
    <Layout>
      <motion.div 
        className="blog-container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="blog-header" variants={itemVariants}>
          <h1>Research & News</h1>
          
          <div className="content-type-filters">
            {types.map(type => (
              <button
                key={type}
                className={`type-button ${activeType === type ? 'active' : ''}`}
                onClick={() => handleTypeChange(type)}
              >
                {type === 'all' ? 'All Content' : 
                 type === 'paper' ? 'Research Papers' : 'News'}
              </button>
            ))}
          </div>

          {activeType === 'all' && (
            <div className="tag-filters">
              {tags.map(tag => (
                <button
                  key={tag}
                  className={`tag-button ${activeTag === tag ? 'active' : ''}`}
                  onClick={() => setActiveTag(tag)}
                >
                  {tag.charAt(0).toUpperCase() + tag.slice(1)}
                </button>
              ))}
            </div>
          )}
        </motion.div>

        <motion.div className="blog-grid" variants={containerVariants}>
          {filteredContent.map(item => (
            <motion.article 
              key={item.id} 
              className={`blog-card ${item.type === 'paper' ? 'paper-card' : ''}`}
              variants={itemVariants}
            >
              <div className="blog-card-content">
                <span className="blog-tag">{item.tag}</span>
                <h2>{item.title}</h2>
                {item.summary && <p className="blog-summary">{item.summary}</p>}
                <span className="blog-date">{formatDate(item.date)}</span>
                {item.link.startsWith('/papers') ? (
                  <Link to={item.link} className="read-more">
                    Read Paper →
                  </Link>
                ) : (
                  <a 
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="read-more"
                  >
                    Read More →
                  </a>
                )}
              </div>
            </motion.article>
          ))}
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default Blog; 