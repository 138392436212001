export const aiTheme = {
  colors: {
    midnight: '#000C2E',
    platinum: '#E5E5E5',
    neonBlue: '#00F0FF',
    neonPurple: '#7B00FF',
    darkBlue: '#001142'
  },
  gradients: {
    primary: 'linear-gradient(45deg, #00F0FF, #7B00FF)',
    secondary: 'linear-gradient(45deg, #7B00FF, #00F0FF)'
  },
  transitions: {
    smooth: 'all 0.3s ease-in-out',
    slower: 'all 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
  }
};

export const animations = {
  fadeIn: {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.6 }
  },
  slideUp: {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  }
}; 