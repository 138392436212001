import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';

function WaitlistModal({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    interests: [],
    useCase: ''
  });
  const [status, setStatus] = useState('idle'); // 'idle' | 'submitting' | 'success' | 'error'
  const [errorMessage, setErrorMessage] = useState('');

  if (!isOpen) return null;

  const interestOptions = [
    'Private AI Infrastructure',
    'Enterprise Solutions',
    'Custom AI Development',
    'Quantum Computing',
    'AI Consulting',
    'Research Collaboration'
  ];

  const handleInterestChange = (interest) => {
    setFormData(prev => ({
      ...prev,
      interests: prev.interests.includes(interest)
        ? prev.interests.filter(i => i !== interest)
        : [...prev.interests, interest]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('submitting');
    
    try {
        console.log('Submitting form:', formData);

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submitWaitlist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': process.env.REACT_APP_API_KEY
            },
            body: JSON.stringify({
                ...formData,
                submittedAt: new Date().toISOString(),
                source: 'waitlist_form'
            })
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.error || 'Failed to submit');
        }

        setStatus('success');
        // Close modal after 3 seconds
        setTimeout(onClose, 3000);
    } catch (error) {
        console.error('Error submitting form:', error);
        setStatus('error');
        setErrorMessage(error.message || 'Failed to submit. Please try again.');
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'submitting':
        return (
          <div className="submission-status">
            <div className="loading-spinner"></div>
            <h3>Submitting...</h3>
            <p>Please wait while we process your request.</p>
          </div>
        );
      
      case 'success':
        return (
          <div className="submission-status success">
            <span className="status-emoji">🎉</span>
            <h3>Thank You!</h3>
            <p>We're excited to have you on board. We'll be in touch soon!</p>
          </div>
        );
      
      case 'error':
        return (
          <div className="submission-status error">
            <span className="status-emoji">❌</span>
            <h3>Oops!</h3>
            <p>{errorMessage}</p>
            <button 
              className="retry-button"
              onClick={() => setStatus('idle')}
            >
              Try Again
            </button>
          </div>
        );
      
      default:
        return (
          <>
            <div className="modal-header">
              <span className="modal-emoji">🚀</span>
              <h2>Join the Waitlist</h2>
              <button className="modal-close" onClick={onClose}>×</button>
            </div>
            
            <div className="modal-scroll-content">
              <form onSubmit={handleSubmit} className="waitlist-form">
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input
                    type="text"
                    id="name"
                    required
                    value={formData.name}
                    onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    required
                    value={formData.email}
                    onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="company">Company</label>
                  <input
                    type="text"
                    id="company"
                    value={formData.company}
                    onChange={e => setFormData(prev => ({ ...prev, company: e.target.value }))}
                  />
                </div>

                <div className="form-group">
                  <label>Areas of Interest</label>
                  <div className="interest-options">
                    {interestOptions.map(interest => (
                      <label key={interest} className="interest-checkbox">
                        <input
                          type="checkbox"
                          checked={formData.interests.includes(interest)}
                          onChange={() => handleInterestChange(interest)}
                        />
                        <span>{interest}</span>
                      </label>
                    ))}
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="useCase">Tell us about your use case</label>
                  <textarea
                    id="useCase"
                    value={formData.useCase}
                    onChange={e => setFormData(prev => ({ ...prev, useCase: e.target.value }))}
                    rows="3"
                  />
                </div>
              </form>
            </div>

            <div className="modal-footer">
              <button type="submit" className="submit-button" onClick={handleSubmit}>
                Join Waitlist →
              </button>
            </div>
          </>
        );
    }
  };

  return (
    <AnimatePresence>
      <motion.div 
        className="modal-overlay"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={status === 'idle' ? onClose : undefined}
      >
        <motion.div 
          className="modal-content waitlist-modal"
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.8, opacity: 0 }}
          onClick={e => e.stopPropagation()}
        >
          {status === 'idle' ? (
            <>
              <div className="modal-header">
                <span className="modal-emoji">🚀</span>
                <h2>Join the Waitlist</h2>
                <button className="modal-close" onClick={onClose}>×</button>
              </div>
              
              <div className="modal-scroll-content">
                <form onSubmit={handleSubmit} className="waitlist-form">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      id="name"
                      required
                      value={formData.name}
                      onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      required
                      value={formData.email}
                      onChange={e => setFormData(prev => ({ ...prev, email: e.target.value }))}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="company">Company</label>
                    <input
                      type="text"
                      id="company"
                      value={formData.company}
                      onChange={e => setFormData(prev => ({ ...prev, company: e.target.value }))}
                    />
                  </div>

                  <div className="form-group">
                    <label>Areas of Interest</label>
                    <div className="interest-options">
                      {interestOptions.map(interest => (
                        <label key={interest} className="interest-checkbox">
                          <input
                            type="checkbox"
                            checked={formData.interests.includes(interest)}
                            onChange={() => handleInterestChange(interest)}
                          />
                          <span>{interest}</span>
                        </label>
                      ))}
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="useCase">Tell us about your use case</label>
                    <textarea
                      id="useCase"
                      value={formData.useCase}
                      onChange={e => setFormData(prev => ({ ...prev, useCase: e.target.value }))}
                      rows="3"
                    />
                  </div>
                </form>
              </div>

              <div className="modal-footer">
                <button type="submit" className="submit-button" onClick={handleSubmit}>
                  Join Waitlist →
                </button>
              </div>
            </>
          ) : (
            renderContent()
          )}
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}

export default WaitlistModal; 