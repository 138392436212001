import Layout from '../components/Layout';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

function Solutions() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <Layout
      title="Our Solutions"
      description="Discover how our AI solutions can transform your business."
    >
      <motion.div
        className="solutions-showcase"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Featured Solution */}
        <motion.div className="featured-solution" variants={itemVariants}>
          <div className="solution-content">
            <h2>Cognitive Enterprise Suite</h2>
            <p className="solution-tagline">Transform your business with AI-powered intelligence</p>
            <div className="solution-highlights">
              <div className="highlight-item">
                <span className="highlight-icon">🎯</span>
                <span className="highlight-text">300% Efficiency Boost</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-icon">⚡</span>
                <span className="highlight-text">Real-time Processing</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-icon">🔄</span>
                <span className="highlight-text">Seamless Integration</span>
              </div>
            </div>
          </div>
        </motion.div>

        {/* New Featured Solution */}
        <motion.div className="featured-solution jaira-feature" variants={itemVariants}>
          <div className="solution-content">
            <span className="solution-badge">New</span>
            <h2>Jaira: AI in a Box</h2>
            <p className="solution-tagline">Your Private AI Powerhouse - Secure, Scalable, Revolutionary</p>
            <div className="solution-highlights">
              <div className="highlight-item">
                <span className="highlight-icon">🔒</span>
                <span className="highlight-text">100% Private</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-icon">⚡</span>
                <span className="highlight-text">Instant Deployment</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-icon">🔄</span>
                <span className="highlight-text">Enterprise Ready</span>
              </div>
            </div>
            <Link to="/jaira" className="learn-more-link">
              <span>Learn More</span>
              <span className="button-icon">→</span>
            </Link>
          </div>
        </motion.div>

        {/* Core Solutions Grid */}
        <motion.div className="solutions-section" variants={itemVariants}>
          <h2 className="section-title">Core Solutions</h2>
          <div className="solutions-grid">
            <motion.div className="solution-card" variants={itemVariants}>
              <div className="solution-icon">🧠</div>
              <h3>Machine Learning</h3>
              <p>Advanced algorithms that learn and adapt to your business needs, delivering unprecedented accuracy and efficiency.</p>
              <ul className="solution-features">
                <li>Predictive Analytics</li>
                <li>Pattern Recognition</li>
                <li>Automated Learning</li>
              </ul>
            </motion.div>

            <motion.div className="solution-card" variants={itemVariants}>
              <div className="solution-icon">💬</div>
              <h3>Natural Language Processing</h3>
              <p>Understanding and processing human language with state-of-the-art accuracy and contextual awareness.</p>
              <ul className="solution-features">
                <li>Sentiment Analysis</li>
                <li>Text Classification</li>
                <li>Language Generation</li>
              </ul>
            </motion.div>

            <motion.div className="solution-card" variants={itemVariants}>
              <div className="solution-icon">👁️</div>
              <h3>Computer Vision</h3>
              <p>Visual intelligence that sees and understands the world with human-like perception and beyond.</p>
              <ul className="solution-features">
                <li>Object Detection</li>
                <li>Image Recognition</li>
                <li>Visual Search</li>
              </ul>
            </motion.div>
          </div>
        </motion.div>

        {/* Industry Solutions */}
        <motion.div className="solutions-section" variants={itemVariants}>
          <h2 className="section-title">Industry Solutions</h2>
          <div className="industry-grid">
            <motion.div className="industry-card" variants={itemVariants}>
              <span className="industry-badge">Healthcare</span>
              <h3>Medical Intelligence Suite</h3>
              <p>AI-powered diagnostics and patient care optimization</p>
            </motion.div>

            <motion.div className="industry-card" variants={itemVariants}>
              <span className="industry-badge">Finance</span>
              <h3>FinTech AI Platform</h3>
              <p>Intelligent trading and risk assessment systems</p>
            </motion.div>

            <motion.div className="industry-card" variants={itemVariants}>
              <span className="industry-badge">Manufacturing</span>
              <h3>Smart Factory OS</h3>
              <p>Automated quality control and process optimization</p>
            </motion.div>

            <motion.div className="industry-card" variants={itemVariants}>
              <span className="industry-badge">Retail</span>
              <h3>Retail Intelligence Hub</h3>
              <p>Personalized shopping experiences and inventory optimization</p>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default Solutions; 