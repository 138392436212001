import Layout from '../components/Layout';
import { motion } from 'framer-motion';

function About() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8 }
    }
  };

  return (
    <Layout
      title="About Us"
      description="Pioneering the future of artificial intelligence with purpose and innovation."
    >
      <motion.div 
        className="about-content"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="about-teaser" variants={itemVariants}>
          <div className="teaser-badge">Coming Soon</div>
          <h2>Our Story is Being Written</h2>
          <p className="teaser-text">
            We're crafting something extraordinary. Soon, we'll unveil the visionaries, innovators, 
            and dreamers behind INFINIA AI's mission to revolutionize artificial intelligence.
          </p>
          <div className="preview-stats">
            <div className="stat-item">
              <span className="stat-icon">🌍</span>
              <span className="stat-label">Global Impact</span>
            </div>
            <div className="stat-item">
              <span className="stat-icon">🚀</span>
              <span className="stat-label">Innovation First</span>
            </div>
            <div className="stat-item">
              <span className="stat-icon">🤝</span>
              <span className="stat-label">Human-Centered</span>
            </div>
          </div>
          {/* <div className="teaser-footer">
            <p>Want to be part of our story?</p>
            <motion.button 
              className="cta-button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Join Our Journey
            </motion.button>
          </div> */}
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default About; 