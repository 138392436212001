import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useLocation } from 'react-router-dom';
import { animations } from './theme/aiSettings';
import './App.css';
import GoogleAnalytics from './components/GoogleAnalytics';

// Import pages
import Home from './pages/Home';
import Solutions from './pages/Solutions';
import Technology from './pages/Technology';
import About from './pages/About';
import Careers from './pages/Careers';
import Projects from './pages/Projects';
import Services from './pages/Services';
import Jaira from './pages/Jaira';
import RAGEvaluation from './pages/Papers/RAGEvaluation';
import Blog from './pages/Blog';
import JairaLLMAdvancements from './pages/Papers/JairaLLMAdvancements';
import QuantumNeuralNetworks from './pages/Papers/QuantumNeuralNetworks';
import SelfEvolvingAI from './pages/Papers/SelfEvolvingAI';
import LLMsBusinessFuture from './pages/Papers/LLMsBusinessFuture';
import QuantumAIFuture from './pages/Papers/QuantumAIFuture';

// Create a new NavLink component
function NavLink({ to, children }) {
  const location = useLocation();
  const isActive = location.pathname === to;
  
  return (
    <Link to={to} className={`nav-link ${isActive ? 'active' : ''}`}>
      {children}
      {isActive && (
        <motion.div
          className="nav-indicator"
          layoutId="nav-indicator"
          initial={false}
          transition={{
            type: "spring",
            stiffness: 380,
            damping: 30
          }}
        />
      )}
    </Link>
  );
}

function App() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <Router>
      <div className="App">
        <GoogleAnalytics />
        
        <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
          <motion.div 
            className="logo"
            initial={animations.fadeIn.initial}
            animate={animations.fadeIn.animate}
            transition={animations.fadeIn.transition}
          >
            <Link to="/">INFINIA AI</Link>
          </motion.div>
          
          {/* Mobile Menu Button */}
          <button 
            className="mobile-menu-button"
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            <span className={`hamburger ${mobileMenuOpen ? 'open' : ''}`}></span>
          </button>

          {/* Desktop Navigation */}
          <motion.div 
            className="nav-links desktop-nav"
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <NavLink to="/solutions">Solutions</NavLink>
            <NavLink to="/technology">Technology</NavLink>
            <NavLink to="/projects">Projects</NavLink>
            <NavLink to="/services">Services</NavLink>
            <NavLink to="/about">About</NavLink>
            <NavLink to="/careers">Careers</NavLink>
            <NavLink to="/blog">Blog</NavLink>
          </motion.div>

          {/* Mobile Navigation */}
          <motion.div 
            className={`mobile-nav ${mobileMenuOpen ? 'open' : ''}`}
            initial={false}
            animate={mobileMenuOpen ? { x: 0 } : { x: "100%" }}
            transition={{ type: "spring", damping: 20 }}
          >
            <NavLink to="/solutions" onClick={() => setMobileMenuOpen(false)}>Solutions</NavLink>
            <NavLink to="/technology" onClick={() => setMobileMenuOpen(false)}>Technology</NavLink>
            <NavLink to="/projects" onClick={() => setMobileMenuOpen(false)}>Projects</NavLink>
            <NavLink to="/services" onClick={() => setMobileMenuOpen(false)}>Services</NavLink>
            <NavLink to="/about" onClick={() => setMobileMenuOpen(false)}>About</NavLink>
            <NavLink to="/careers" onClick={() => setMobileMenuOpen(false)}>Careers</NavLink>
            <NavLink to="/blog" onClick={() => setMobileMenuOpen(false)}>Blog</NavLink>
          </motion.div>
        </nav>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/technology" element={<Technology />} />
            <Route path="/about" element={<About />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/services" element={<Services />} />
            <Route path="/jaira" element={<Jaira />} />
            <Route path="/papers/rag-evaluation" element={<RAGEvaluation />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/papers/JairaLLMAdvancements" element={<JairaLLMAdvancements />} />
            <Route path="/papers/quantum-neural-networks" element={<QuantumNeuralNetworks />} />
            <Route path="/papers/self-evolving-ai" element={<SelfEvolvingAI />} />
            <Route path="/papers/llms-business-future" element={<LLMsBusinessFuture />} />
            <Route path="/papers/quantum-ai-future" element={<QuantumAIFuture />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
