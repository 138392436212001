import Layout from '../components/Layout';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import WaitlistModal from '../components/WaitlistModal';
import Chat from '../components/Chat';

function Jaira() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <Layout
      title="Jaira: AI in a Box"
      description="Your Private AI Powerhouse - Secure, Scalable, Revolutionary"
    >
      <motion.div
        className="jaira-page"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Hero Section */}
        <motion.div className="hero-section" variants={itemVariants}>
          <h1>Meet Jaira</h1>
          <p>Your Enterprise AI Assistant</p>
        </motion.div>

        {/* Key Features */}
        <motion.div className="features-section" variants={itemVariants}>
          <div className="features-grid">
            <div className="feature-card">
              <div className="feature-icon">🔒</div>
              <h3>Complete Privacy</h3>
              <p>Your data never leaves your premises. Jaira operates entirely within your secure infrastructure.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">🧠</div>
              <h3>Hybrid AI Core</h3>
              <p>Combines fine-tuned models for reasoning with real-time knowledge retrieval for maximum accuracy.</p>
            </div>
            <div className="feature-card">
              <div className="feature-icon">⚡</div>
              <h3>Instant Deployment</h3>
              <p>Up and running in hours, not months. Pre-configured for immediate value delivery.</p>
            </div>
          </div>
        </motion.div>

        {/* New Technical Architecture Section */}
        <motion.div className="tech-architecture-section" variants={itemVariants}>
          <h2>Technical Architecture</h2>
          <div className="architecture-grid">
            <div className="architecture-card">
              <div className="arch-icon">🎯</div>
              <h4>Fine-Tuned Core</h4>
              <p>Domain-specific training ensures Jaira understands your industry's context and terminology.</p>
              <ul className="tech-features">
                <li>Personality & Reasoning</li>
                <li>Industry Knowledge</li>
                <li>Custom Behaviors</li>
              </ul>
            </div>
            <div className="architecture-card">
              <div className="arch-icon">🔄</div>
              <h4>Dynamic Retrieval</h4>
              <p>Real-time access to your organization's knowledge base for up-to-date, accurate responses.</p>
              <ul className="tech-features">
                <li>Document Search</li>
                <li>Fact Verification</li>
                <li>Live Updates</li>
              </ul>
            </div>
            <div className="architecture-card">
              <div className="arch-icon">🔗</div>
              <h4>Hybrid Processing</h4>
              <p>Seamless integration of retrieved information with fine-tuned reasoning for optimal results.</p>
              <ul className="tech-features">
                <li>Smart Routing</li>
                <li>Context Fusion</li>
                <li>Accuracy Checks</li>
              </ul>
            </div>
          </div>
        </motion.div>

        {/* Progress Timeline */}
        <motion.div className="progress-section" variants={itemVariants}>
          <h2>Development Timeline</h2>
          <div className="timeline">
            <div className="timeline-item completed">
              <span className="timeline-date">Q4 2024</span>
              <h4>Core Architecture</h4>
              <p>Foundation architecture and fine-tuning pipeline established</p>
            </div>
            <div className="timeline-item completed">
              <span className="timeline-date">Q1 2025</span>
              <h4>Beta Testing</h4>
              <p>RAG integration and enterprise deployment validation</p>
            </div>
            <div className="timeline-item current">
              <span className="timeline-date">Q2 2025</span>
              <h4>Launch Phase</h4>
              <p>Production release with full hybrid capabilities</p>
            </div>
            <div className="timeline-item">
              <span className="timeline-date">Q3 2025</span>
              <h4>Advanced Features</h4>
              <p>Enhanced reasoning models and expanded knowledge integration</p>
            </div>
          </div>
        </motion.div>

        {/* Use Cases */}
        <motion.div className="use-cases-section" variants={itemVariants}>
          <h2>Transform Your Enterprise</h2>
          <div className="use-cases-grid">
            <div className="use-case-card">
              <h4>Data Analysis</h4>
              <p>Instant insights from your enterprise data</p>
            </div>
            <div className="use-case-card">
              <h4>Customer Support</h4>
              <p>24/7 intelligent support automation</p>
            </div>
            <div className="use-case-card">
              <h4>Knowledge Management</h4>
              <p>Smart documentation and knowledge base</p>
            </div>
            <div className="use-case-card">
              <h4>Process Automation</h4>
              <p>Streamlined workflows and operations</p>
            </div>
          </div>
        </motion.div>

        {/* New FAQ Section */}
        <motion.div className="faq-section" variants={itemVariants}>
          <h2>Technical FAQ</h2>
          <div className="faq-grid">
            <div className="faq-item">
              <h4>Why Use Both Fine-Tuning & RAG?</h4>
              <p>This hybrid approach combines the best of both worlds: consistent personality and reasoning from fine-tuning, with up-to-date accuracy from real-time retrieval.</p>
            </div>
            <div className="faq-item">
              <h4>How is Data Privacy Maintained?</h4>
              <p>All processing occurs within your infrastructure. Fine-tuned models and knowledge bases remain exclusively on your servers.</p>
            </div>
            <div className="faq-item">
              <h4>What About Model Updates?</h4>
              <p>Regular updates to both fine-tuned models and retrieval systems ensure Jaira stays current with the latest AI advancements.</p>
            </div>
          </div>
        </motion.div>

        {/* CTA Section */}
        <motion.div className="cta-section" variants={itemVariants}>
          <h2>Ready to Transform Your Enterprise?</h2>
          <p>Join the AI revolution with complete privacy and control</p>
          <motion.button
            className="cta-button"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsModalOpen(true)}
          >
            Request Early Access →
          </motion.button>
        </motion.div>

        <WaitlistModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
        />

        <motion.div className="chat-section" variants={itemVariants}>
          <Chat />
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default Jaira; 