import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ParticleField from '../components/ParticleField.js';
import NewsSection from '../components/NewsSection.js';
import Footer from '../components/Footer.js';
import { useState } from 'react';
import WaitlistModal from '../components/WaitlistModal';
import NewsTicker from '../components/NewsTicker.js';
import Chat from '../components/Chat';

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ParticleField />
      <NewsTicker />
      <motion.h1 
          className="hero-title"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          The Intelligence Evolution
        </motion.h1>
      
      {/* Hero Section */}
      <motion.div 
        className="hero-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {/* Add Startup Banner */}
        <motion.div
          className="startup-banner"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <span className="startup-badge">Startup</span>
          <p>Now accepting clients for June 2025 cohort</p>
          <button 
            onClick={() => setIsModalOpen(true)} 
            className="waitlist-link"
          >
            Join Waitlist →
          </button>
        </motion.div>

        
        
        {/* Animated Metrics Bar */}
        <motion.div 
          className="metrics-bar"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <div className="metric">
            <div className="metric-icon">
              <span className="pulse-circle"></span>
              🎯
            </div>
            <div className="metric-content">
              <span className="metric-value">12+</span>
              <span className="metric-label">AI Models</span>
            </div>
          </div>
          <div className="metric">
            <div className="metric-icon">
              <span className="pulse-circle"></span>
              ⚡
            </div>
            <div className="metric-content">
              <span className="metric-value">100%</span>
              <span className="metric-label">Open Source</span>
            </div>
          </div>
          <div className="metric">
            <div className="metric-icon">
              <span className="pulse-circle"></span>
              🔬
            </div>
            <div className="metric-content">
              <span className="metric-value">24/7</span>
              <span className="metric-label">Research</span>
            </div>
          </div>
        </motion.div>



        {/* Add Jaira Showcase after the metrics bar */}
        <motion.div 
          className="featured-product"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <div className="featured-content">
            <span className="featured-badge">Featured Product</span>
            <h2>Jaira: AI in a Box</h2>
            <p className="featured-description">
              Deploy enterprise-grade AI within your secure infrastructure. 
              Complete privacy, instant deployment, revolutionary capabilities.
            </p>
            <div className="featured-highlights">
              <div className="highlight">
                <span className="highlight-icon">🔒</span>
                <span>100% Private</span>
              </div>
              <div className="highlight">
                <span className="highlight-icon">⚡</span>
                <span>Instant Setup</span>
              </div>
              <div className="highlight">
                <span className="highlight-icon">🔄</span>
                <span>Hybrid AI</span>
              </div>
            </div>
            <Link to="/jaira" className="featured-cta">
              <span>Explore Jaira</span>
              <span className="arrow">→</span>
            </Link>
          </div>
          <div className="featured-visual">
            <div className="tech-orb"></div>
          </div>
        </motion.div>

        {/* News Section - Moved inside hero container */}
        <motion.div 
          className="news-section-wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <NewsSection />
        </motion.div>
      </motion.div>

      {/* Main Features Section */}
      <motion.div 
        className="features-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5 }}
      >
        <div className="features-grid">
          <motion.div 
            className="feature-block"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <div className="feature-icon">🧠</div>
            <h3>Advanced AI Solutions</h3>
            <p>Cutting-edge artificial intelligence tailored to your needs</p>
          </motion.div>

          <motion.div 
            className="feature-block"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            <div className="feature-icon">🔮</div>
            <h3>Predictive Analytics</h3>
            <p>Transform data into actionable insights with AI-powered predictions</p>
          </motion.div>

          <motion.div 
            className="feature-block"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
          >
            <div className="feature-icon">🚀</div>
            <h3>Scalable Infrastructure</h3>
            <p>Enterprise-grade AI infrastructure that grows with your business</p>
          </motion.div>
        </div>

        <motion.div 
          className="cta-section"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
        >
          <h2>Ready to Transform Your Business?</h2>
          <Link to="/solutions">
            <motion.button 
              className="primary-cta"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get Started →
            </motion.button>
          </Link>
        </motion.div>
      </motion.div>

      <WaitlistModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
      />
      
      <Footer />

              {/* Add Jaira Chat Section */}
              <motion.div 
          className="jaira-section"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
         
          <Chat />
        </motion.div>
    </>
  );
}

export default Home; 