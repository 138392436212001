import Layout from '../components/Layout';
import { motion } from 'framer-motion';

function Technology() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <Layout
      title="Our Technology"
      description="Pioneering the future with cutting-edge AI technology."
    >
      <motion.div 
        className="tech-showcase"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {/* Core Technologies Section */}
        <motion.div className="tech-section" variants={itemVariants}>
          <h2 className="tech-section-title">Core Technologies</h2>
          <div className="tech-grid">
            <div className="tech-feature">
              <div className="tech-icon">🧠</div>
              <h3>Neural Networks</h3>
              <p>State-of-the-art deep learning architectures powering next-generation AI solutions with unprecedented accuracy and efficiency.</p>
            </div>
            <div className="tech-feature">
              <div className="tech-icon">☁️</div>
              <h3>Cloud Infrastructure</h3>
              <p>Scalable, secure, and reliable cloud-native solutions built on cutting-edge infrastructure for maximum performance.</p>
            </div>
            <div className="tech-feature">
              <div className="tech-icon">🔒</div>
              <h3>Security First</h3>
              <p>Enterprise-grade security protocols and encryption ensuring your data remains protected at all times.</p>
            </div>
          </div>
        </motion.div>

        {/* Innovation Pipeline Section */}
        <motion.div className="tech-section" variants={itemVariants}>
          <h2 className="tech-section-title">Innovation Pipeline</h2>
          <div className="pipeline-container">
            <div className="pipeline-stage">
              <span className="stage-badge">Research</span>
              <p>Exploring breakthrough AI architectures and methodologies</p>
            </div>
            <div className="pipeline-stage">
              <span className="stage-badge">Development</span>
              <p>Transforming research into practical applications</p>
            </div>
            <div className="pipeline-stage">
              <span className="stage-badge">Testing</span>
              <p>Rigorous validation and performance optimization</p>
            </div>
            <div className="pipeline-stage">
              <span className="stage-badge">Deployment</span>
              <p>Seamless integration into production environments</p>
            </div>
          </div>
        </motion.div>

        {/* Coming Soon Section */}
        <motion.div className="tech-section coming-soon" variants={itemVariants}>
          <h2 className="tech-section-title">On the Horizon</h2>
          <div className="coming-soon-grid">
            <div className="soon-feature">
              <span className="soon-badge">Coming Soon</span>
              <h3>Advanced LLM Integration</h3>
              <p>Next-generation language model capabilities with enhanced reasoning and domain expertise.</p>
            </div>
            <div className="soon-feature">
              <span className="soon-badge">Coming Soon</span>
              <h3>Quantum AI Research</h3>
              <p>Exploring quantum computing applications in artificial intelligence.</p>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default Technology; 