import { motion } from 'framer-motion';
import Layout from '../../components/Layout';
import '../../styles/Paper.css';

function QuantumNeuralNetworks() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <Layout>
      <motion.div
        className="paper-container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="paper-content" variants={itemVariants}>
          <h1>Quantum Neural Networks: A New Frontier in AI</h1>
          
          <motion.div className="paper-author" variants={itemVariants}>
            <p className="author-info">
              By <span className="author-name">Mr. John Bellew</span>
              <span className="author-title">Chief AI Architect, INFINIA AI</span>
            </p>
            <p className="publication-date">February 9, 2025</p>
          </motion.div>


          <motion.div className="paper-abstract" variants={itemVariants}>
            <h2>Abstract</h2>
            <p>
              As artificial intelligence continues to revolutionize industries, researchers and innovators 
              are turning to quantum computing to break the limits of traditional neural networks. This paper 
              explores the intersection of quantum computing and neural networks, examining how Quantum Neural 
              Networks (QNNs) represent the next frontier in AI capabilities, combining the vast computational 
              power of quantum mechanics with deep learning architectures.
            </p>
          </motion.div>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>1. Introduction: The Next Evolution in AI</h2>
            <p>
              Quantum Neural Networks represent a paradigm shift in artificial intelligence, 
              merging quantum computing's unprecedented processing capabilities with the 
              adaptability of neural networks. This convergence promises to revolutionize 
              how we approach complex computational problems and machine learning challenges.
            </p>
          </motion.section>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>2. What Are Quantum Neural Networks?</h2>
            <p>
              Unlike classical neural networks, QNNs leverage quantum bits (qubits) to perform 
              operations exponentially faster. Key characteristics include:
            </p>
            <ul>
              <li>Quantum superposition for parallel processing</li>
              <li>Entanglement-enhanced information processing</li>
              <li>Quantum interference for optimization</li>
              <li>Hybrid classical-quantum architectures</li>
            </ul>
          </motion.section>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>3. Key Breakthroughs Driving QNNs Forward</h2>
            <p>Recent advancements have accelerated QNN development:</p>
            <ul>
              <li>
                <strong>Quantum Supremacy:</strong> Google's Sycamore processor demonstration
              </li>
              <li>
                <strong>Variational Circuits:</strong> IBM's quantum AI research initiatives
              </li>
              <li>
                <strong>Hybrid Systems:</strong> INFINIA AI's Quantum 2100 interface
              </li>
            </ul>
          </motion.section>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>4. Transformative Possibilities</h2>
            <p>QNNs unlock new potential in AI applications:</p>
            <ul>
              <li>
                <strong>Exponential Speedups:</strong> Training AI models in seconds instead of weeks
              </li>
              <li>
                <strong>Complex Pattern Recognition:</strong> Solving previously intractable problems
              </li>
              <li>
                <strong>Next-Gen AI Assistants:</strong> Self-evolving AI with real-time adaptation
              </li>
            </ul>
          </motion.section>

          <motion.section className="paper-section" variants={itemVariants}>
            <h2>5. Challenges & The Road Ahead</h2>
            <p>Current limitations include:</p>
            <ul>
              <li>Hardware fragility and cooling requirements</li>
              <li>Algorithmic complexity in quantum-compatible models</li>
              <li>Cost barriers to widespread adoption</li>
            </ul>
          </motion.section>

          <motion.div className="paper-conclusion" variants={itemVariants}>
            <h2>Conclusion</h2>
            <p>
              While challenges remain, the potential of Quantum Neural Networks to revolutionize 
              AI is immense. As quantum hardware matures and algorithms advance, we expect QNNs 
              to become increasingly practical and transformative across industries within the 
              next decade.
            </p>
          </motion.div>

          <motion.div className="paper-references" variants={itemVariants}>
            <h2>References</h2>
            <ol>
              <li>Arute, F. et al. (2023). "Quantum Supremacy Using a Programmable Superconducting Processor." Nature</li>
              <li>IBM Research. (2024). "Advances in Quantum Machine Learning." IBM Journal of Research and Development</li>
              <li>Zhang, M. et al. (2024). "Hybrid Quantum-Classical Neural Networks." ArXiv:2024.08234</li>
            </ol>
          </motion.div>
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default QuantumNeuralNetworks; 