import Layout from '../components/Layout';
import { motion } from 'framer-motion';

function Careers() {
  return (
    <Layout
      title="Careers"
      description="Join our team of innovators shaping the future of AI in Ireland and globally."
    >
      <div className="careers-content">
        <div className="benefits-section">
          <h3>Why Join Us?</h3>
          <div className="benefits-grid">
            <div className="benefit-card">
              <h4>Innovation Hub</h4>
              <p>Be part of Dublin's thriving tech ecosystem and work on cutting-edge AI technology.</p>
            </div>
            <div className="benefit-card">
              <h4>Growth & Learning</h4>
              <p>Continuous learning opportunities with leading Irish universities and tech partners.</p>
            </div>
            <div className="benefit-card">
              <h4>Work-Life Balance</h4>
              <p>Flexible working arrangements and generous holiday allowance.</p>
            </div>
            <div className="benefit-card">
              <h4>Great Benefits</h4>
              <p>Competitive salary, health insurance, pension scheme, and regular team events.</p>
            </div>
          </div>
        </div>

        <div className="openings-section">
          <h3>Open Positions</h3>
          <div className="job-list">
            <div className="job-card">
              <div className="job-info">
                <h4>Senior AI Engineer</h4>
                <p>Dublin, Ireland (Hybrid)</p>
                <span className="job-tag">Full-time</span>
              </div>
              <button className="apply-button">Apply Now</button>
            </div>
            <div className="job-card">
              <div className="job-info">
                <h4>Machine Learning Researcher</h4>
                <p>Dublin, Ireland (On-site)</p>
                <span className="job-tag">Full-time</span>
              </div>
              <button className="apply-button">Apply Now</button>
            </div>
            <div className="job-card">
              <div className="job-info">
                <h4>AI Solutions Architect</h4>
                <p>Dublin, Ireland (Hybrid)</p>
                <span className="job-tag">Full-time</span>
              </div>
              <button className="apply-button">Apply Now</button>
            </div>
            <div className="job-card">
              <div className="job-info">
                <h4>Graduate AI Developer</h4>
                <p>Dublin, Ireland (On-site)</p>
                <span className="job-tag">Full-time</span>
              </div>
              <button className="apply-button">Apply Now</button>
            </div>
          </div>
        </div>

        <div className="location-section">
          <h3>Our Dublin Office</h3>
          <p>Located in Malahide, Dublin - our modern office space offers:</p>
          <div className="office-features-grid">
            <div className="feature-card">
              <h4>Central Location</h4>


              <p>Easy access via DART and Bus</p>
            </div>
            <div className="feature-card">
              <h4>Modern Facilities</h4>
              <p>State-of-the-art workspace and amenities</p>
            </div>
            <div className="feature-card">
              <h4>Community</h4>
              <p>Regular tech meetups and community events</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Careers; 