import Layout from '../components/Layout';
import { motion } from 'framer-motion';

function Projects() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8
      }
    }
  };

  return (
    <Layout
      title="Our Projects"
      description="Exploring the frontiers of AI innovation with groundbreaking projects."
    >
      {/* Quantum 2100 Featured Project */}
      <motion.div 
        className="featured-project quantum-feature"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.a 
          href="https://quantum2100lx.azurewebsites.net/"
          target="_blank"
          rel="noopener noreferrer"
          className="quantum-2100-card"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          <div className="quantum-2100-content">
            <div className="project-badge">Featured Project</div>
            <h3>Quantum 2100</h3>
            <p className="quantum-tagline">
              A mind-bending neural interface fusing quantum computing with consciousness visualization
            </p>
            <div className="feature-highlights">
              <div className="highlight">
                <span className="highlight-icon">🧠</span>
                <span>Neural Interface</span>
              </div>
              <div className="highlight">
                <span className="highlight-icon">💫</span>
                <span>Quantum Computing</span>
              </div>
              <div className="highlight">
                <span className="highlight-icon">🌌</span>
                <span>Holographic Display</span>
              </div>
            </div>
            <div className="cta-link">
              Experience the Future
              <span className="arrow">→</span>
            </div>
          </div>
        </motion.a>
      </motion.div>

      {/* Other Projects */}
      <motion.div
        className="projects-grid"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="project-card" variants={itemVariants}>
          <h4>Aurora AI Assistant</h4>
          <p>
            Next-generation AI companion with advanced natural language understanding
            and contextual awareness.
          </p>
          <div className="project-tech-stack">
            <span>NLP</span>
            <span>Deep Learning</span>
            <span>Neural Networks</span>
          </div>
        </motion.div>

        <motion.div className="project-card" variants={itemVariants}>
          <h4>NeuroVision</h4>
          <p>
            Advanced computer vision system for real-time object detection and scene
            understanding.
          </p>
          <div className="project-tech-stack">
            <span>Computer Vision</span>
            <span>Machine Learning</span>
            <span>Edge Computing</span>
          </div>
        </motion.div>

        <motion.div className="project-card" variants={itemVariants}>
          <h4>QuantumFlow</h4>
          <p>
            Quantum-inspired optimization algorithms for complex business problems.
          </p>
          <div className="project-tech-stack">
            <span>Quantum Computing</span>
            <span>Optimization</span>
            <span>Cloud Infrastructure</span>
          </div>
        </motion.div>
      </motion.div>
    </Layout>
  );
}

export default Projects; 