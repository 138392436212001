import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import newsData from '../data/news.json';

function NewsSection() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    setNews(newsData.news);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: 0.2 }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  return (
    <section className="news-section">
      <motion.div
        className="news-container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
      
        <div className="news-grid">
          {news.map((item) => (
            <motion.div
              key={item.id}
              className="news-card"
              variants={itemVariants}
            >
              <div className="news-content">
                <span className="news-tag">{item.tag}</span>
                <h3>{item.title}</h3>
                <p>{item.summary}</p>
                <span className="news-date">{formatDate(item.date)}</span>
              </div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
}

export default NewsSection; 