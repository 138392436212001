import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
import newsData from '../data/ai-news.json';

function NewsTicker() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    // Duplicate the news array to create a seamless loop
    setNews([...newsData.news, ...newsData.news]);
  }, []);

  return (
    <>
      {news.length > 0 && (
        <motion.div 
          className="news-ticker"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
        >
          <div className="ticker-label">AI News</div>
          <div className="ticker-wrapper">
            <div className="ticker-content">
              {news.map((item, index) => (
                <div key={`${item.id}-${index}`} style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  <span className="ticker-tag">{item.tag}</span>
                  <a 
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ticker-text"
                  >
                    {item.title}
                  </a>
                  <span style={{ margin: '0 2rem' }}>•</span>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
}

export default NewsTicker; 