import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { AI_CONFIG } from '../config/ai-config';
import { IoExpandOutline, IoContractOutline } from 'react-icons/io5';

function Chat() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const messagesEndRef = useRef(null);
  const [isStreaming, setIsStreaming] = useState(false);
  const [streamingMessage, setStreamingMessage] = useState('');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [provider, setProvider] = useState('openai');

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    } else {
      setMessages([{
        role: 'assistant',
        content: AI_CONFIG.initialMessage.content
      }]);
    }
  }, []);

  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem('chatMessages', JSON.stringify(messages));
    }
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);
    setStreamingMessage('');
    
    try {
      console.log('📤 Sending request:', {
        provider,
        messageCount: messages.length + 1,
        model: provider === 'openai' ? 'gpt-4' : undefined
      });

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.REACT_APP_API_KEY
        },
        body: JSON.stringify({
          messages: [
            AI_CONFIG.systemMessage,
            ...messages.filter(msg => msg.role !== 'system'),
            userMessage
          ],
          stream: true,
          provider: provider,
          model: provider === 'openai' ? 'gpt-4' : undefined,
          temperature: 0.7,
          max_tokens: 800
        })
      });

      console.log('📥 Response status:', response.status);

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('❌ Error response:', errorData);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      let fullMessage = '';

      try {
        while (true) {
          const { value, done } = await reader.read();
          if (done) break;
          
          const chunk = decoder.decode(value);
          console.log('📦 Raw chunk:', chunk);
          buffer += chunk;
          
          const lines = buffer.split('\n');
          buffer = lines.pop() || '';
          
          for (const line of lines) {
            const trimmedLine = line.trim();
            if (!trimmedLine || trimmedLine === 'data: [DONE]') continue;
            
            if (trimmedLine.startsWith('data: ')) {
              try {
                const jsonStr = trimmedLine.slice(6);
                const parsed = JSON.parse(jsonStr);
                
                // Handle Phi-2 format
                if (provider === 'phi') {
                  const content = parsed.choices?.[0]?.delta?.content;
                  if (content) {
                    fullMessage += content;
                    setStreamingMessage(fullMessage);
                    await new Promise(resolve => setTimeout(resolve, 15));
                  }
                } 
                // Handle GPT-4 format
                else if (provider === 'openai') {
                  const content = parsed.content;
                  if (content) {
                    fullMessage += content;
                    setStreamingMessage(fullMessage);
                    await new Promise(resolve => setTimeout(resolve, 15));
                  }
                }
              } catch (e) {
                console.log('⚠️ Error parsing chunk:', e);
                // Don't throw error, just continue to next chunk
                continue;
              }
            }
          }
        }
      } finally {
        if (fullMessage) {
          console.log('✅ Complete message received:', fullMessage.slice(0, 100) + '...');
          setMessages(prev => [...prev, { 
            role: 'assistant', 
            content: fullMessage 
          }]);
        }
        setStreamingMessage('');
      }
    } catch (error) {
      console.error('❌ Chat error:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: "I apologize, but I'm having trouble connecting to my services right now. This might be due to CORS settings or network issues. Please try again later or contact support if the issue persists."
      }]);
    } finally {
      setIsLoading(false);
    }
  };

  const clearChat = () => {
    setMessages([{
      role: 'assistant',
      content: AI_CONFIG.initialMessage.content
    }]);
    localStorage.removeItem('chatMessages');
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  // Custom renderers for markdown components
  const markdownComponents = {
    // Headers
    h1: ({node, ...props}) => <h1 className="chat-h1" {...props} />,
    h2: ({node, ...props}) => <h2 className="chat-h2" {...props} />,
    h3: ({node, ...props}) => <h3 className="chat-h3" {...props} />,
    
    // Lists
    ul: ({node, ...props}) => <ul className="chat-ul" {...props} />,
    ol: ({node, ...props}) => <ol className="chat-ol" {...props} />,
    li: ({node, ...props}) => <li className="chat-li" {...props} />,
    
    // Tables
    table: ({node, ...props}) => <table className="chat-table" {...props} />,
    th: ({node, ...props}) => <th className="chat-th" {...props} />,
    td: ({node, ...props}) => <td className="chat-td" {...props} />,
    
    // Code blocks
    code: ({node, inline, className, children, ...props}) => {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <div className="code-block-wrapper">
          <div className="code-block-header">
            <span>{match[1]}</span>
          </div>
          <pre className="chat-pre">
            <code className={className} {...props}>
              {children}
            </code>
          </pre>
        </div>
      ) : (
        <code className="chat-inline-code" {...props}>
          {children}
        </code>
      );
    },

    // Other elements
    p: ({node, ...props}) => <p className="chat-p" {...props} />,
    a: ({node, ...props}) => <a className="chat-link" target="_blank" rel="noopener noreferrer" {...props} />,
    blockquote: ({node, ...props}) => <blockquote className="chat-quote" {...props} />,
    hr: ({node, ...props}) => <hr className="chat-hr" {...props} />,
  };

  return (
    <div className={`floating-chat-wrapper ${isFullScreen ? 'fullscreen' : ''}`}>
      <motion.div 
        className={`floating-chat-container ${isExpanded ? 'expanded' : ''}`}
        animate={{ 
          width: isExpanded ? (isFullScreen ? "100%" : "360px") : "60px",
          height: isExpanded ? (isFullScreen ? "100vh" : "600px") : "60px",
        }}
        transition={{ type: "spring", stiffness: 200, damping: 25 }}
      >
        <div className="chat-header">
          <div 
            className="chat-header-left"
            onClick={() => !isFullScreen && setIsExpanded(!isExpanded)}
          >
            <div className="chat-avatar">
              <div className="avatar-core"></div>
              <div className="avatar-ring"></div>
            </div>
            {isExpanded && (
              <motion.span className="chat-title">
                Jaira AI
              </motion.span>
            )}
          </div>
          {isExpanded && (
            <div className="chat-header-right">
              <div className="provider-select-wrapper" style={{display: 'none'}}>
                <select 
                  value={provider} 
                  onChange={(e) => setProvider(e.target.value)}
                  className="provider-select"
                  
                >
                  <option value="openai">GPT-4</option>
                  <option value="phi">Phi-4</option>
                </select>
              </div>
              <motion.button
                className="header-btn fullscreen-btn"
                onClick={toggleFullScreen}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {isFullScreen ? <IoContractOutline /> : <IoExpandOutline />}
              </motion.button>
              <motion.button
                className="header-btn clear-chat-btn"
                onClick={clearChat}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span>🗑️</span>
              </motion.button>
            </div>
          )}
        </div>

        {isExpanded && (
          <motion.div 
            className="chat-content"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <div className="messages-container">
              <AnimatePresence>
                {messages.map((msg, idx) => (
                  <motion.div
                    key={idx}
                    className={`message ${msg.role}`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={markdownComponents}
                    >
                      {msg.content}
                    </ReactMarkdown>
                  </motion.div>
                ))}
                {streamingMessage && (
                  <motion.div
                    className="message assistant"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      rehypePlugins={[rehypeRaw]}
                      components={markdownComponents}
                    >
                      {streamingMessage}
                    </ReactMarkdown>
                  </motion.div>
                )}
                {isLoading && !streamingMessage && (
                  <div className="typing-indicator">
                    <span></span><span></span><span></span>
                  </div>
                )}
              </AnimatePresence>
              <div ref={messagesEndRef} />
            </div>

            <form onSubmit={handleSubmit} className="chat-input-form">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Message Jaira..."
                disabled={isLoading}
              />
              <motion.button
                type="submit"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={isLoading}
              >
                <span>→</span>
              </motion.button>
            </form>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}

export default Chat; 
