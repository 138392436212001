import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <motion.footer 
      className="footer"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.5 }}
    >
      <div className="footer-content">
        <div className="footer-section">
          <h4>About Infinia AI</h4>
          <p>
            Pioneering the future of AI technology with innovative solutions for businesses worldwide.
          </p>
          <p>
            <span>📍</span>Malahide, Dublin, Ireland
          </p>
        </div>


        <div className="footer-section">
          <h4>Quick Links</h4>
          <Link to="/solutions">Solutions</Link>
          <Link to="/technology">Technology</Link>
          <Link to="/projects">Projects</Link>
          <Link to="/services">Services</Link>
          <Link to="/about">About</Link>
        </div>

        <div className="footer-section">
          <h4>Connect</h4>
          <a href="https://www.linkedin.com/in/bellew/" target="_blank" rel="noopener noreferrer">
            <span>👨‍💻</span> LinkedIn
          </a>
          
          <a href="mailto:ai@infinia.ie">ai@infinia.ie</a>
        </div>
      </div>


      <div className="footer-bottom">
        <p className="disclaimer">
          AI technology and predictions are subject to inherent uncertainties. 
          Results may vary based on data quality and market conditions.
        </p>
        <p className="copyright">
          © {currentYear} Infinia AI. All rights reserved.
        </p>
      </div>
    </motion.footer>
  );
}

export default Footer; 